import { CSSProperties } from 'react';

export function makeDynamicStyles(styles: Partial<CSSProperties>) {
  return { style: styles };
  const outputObj = {} as any;

  for (const key in styles) {
    const kebabKey = camelToKebabCase(key);
    outputObj[`[data-${kebabKey}]`] = styles[key];
  }

  return outputObj;
}

function camelToKebabCase(str: string) {
  return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

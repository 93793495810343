import React, { forwardRef } from 'react';
import cnj from '../utils/cnj';
import type { colorsKeys, FontsNamesType } from '../helpers/theme';
import classes from './index.module.scss';
import { makeDynamicStyles } from '../utils/makeDynamicStyles';

const defaultTypographyVariant = 'span';

export type fontType =
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '900'
  | 'bold';

export type MarginType =
  | number
  | 'auto'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';

export interface TypographyProps {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'div';
  children: React.ReactNode;
  color?: colorsKeys;
  className?: string;
  withMargin?: boolean;
  noWrap?: boolean;
  font?: fontType;
  size?: number;
  mt?: MarginType;
  mb?: MarginType;
  mr?: MarginType;
  ml?: MarginType;
  m?: MarginType;
  height?: number;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  onClick?: Function;
  onMouseEnter?: Function;
  isTruncated?: boolean;
  isWordWrap?: boolean;
  tooltip?: any;
  tPlacement?: string;
  lineNumber?: number;
}

export type TextProps = Omit<TypographyProps, 'children'> & {
  objectId?: string;
};

const fontList: {
  [key: string]: FontsNamesType;
} = {
  '200': 'regular',
  '300': 'light',
  '400': 'regular',
  '500': 'medium',
  '700': 'bold',
  '900': 'bold',
  bold: 'bold',
};

const variantList = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  span: 'span',
  div: 'div',
};

const Typography = (props: TypographyProps, ref: any) => {
  const {
    children,
    variant = defaultTypographyVariant,
    className,
    isTruncated,
    isWordWrap,
    onClick,
    onMouseEnter,
    tooltip,
    tPlacement,
    lineNumber,
  } = props;

  const Item =
    isTruncated && variant === variantList.span
      ? variantList.div
      : variantList[variant];

  return (
    <>
      <Item
        data-tip={tooltip}
        data-place={tPlacement}
        ref={ref}
        className={cnj(
          classes.base,
          classes.modified,
          isTruncated && classes.truncate,
          isWordWrap && classes.isWordWrap,
          lineNumber && classes.lineNumber,
          classes[`color-${props?.color}`],
          classes[`font-${fontList[props?.font as any]}`],
          !!props?.noWrap && classes.modifiedWithNoWrap,
          !!props?.onClick && classes.modifiedWithClick,
          className
        )}
        {...makeDynamicStyles({
          WebkitLineClamp: !!lineNumber ? lineNumber : undefined,
          lineClamp: !!lineNumber ? lineNumber : undefined,
          fontSize: props?.size,
          textAlign: props?.textAlign,
          lineHeight:
            typeof props?.height === 'number'
              ? `${props?.height}px`
              : undefined,
          margin: props?.m,
          marginTop: props?.mt,
          marginLeft: props?.ml,
          marginRight: props?.mr,
          marginBottom: props?.mb,
        })}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </Item>
    </>
  );
};

export default forwardRef<any, TypographyProps>(Typography);

import classes from './Flex.component.module.scss';

import React, { forwardRef } from 'react';
import type { ReactHTML } from 'react';
import cnj from '../utils/cnj';

type Directions = 'column' | 'row';

interface CompProps {
  as?: keyof ReactHTML;
}

type AsType = CompProps['as'];
// @ts-ignore
type DynamicProps = CompProps & ReactHTML[AsType];

export interface FlexProps extends DynamicProps {
  flexDir?:
    | Directions
    | {
        mobile?: Directions;
        tablet?: Directions;
        midDesktop?: Directions;
        desktop?: Directions;
      };
}

const Flex = (props: FlexProps, ref: any) => {
  const { flexDir = 'column', className, as: HtmlTag = 'div', ...rest } = props;
  const isRowDir = flexDir === 'row';

  return (
    <HtmlTag
      ref={ref}
      {...rest}
      className={cnj(classes.container, isRowDir && classes.dirRow, className)}
    />
  );
};

export default forwardRef<any, DynamicProps>(Flex);
